import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControlDirective, FormGroup, FormGroupDirective } from '@angular/forms';
import { EnumGenerico } from 'app/interfaces/enum/enum-generico';
import { Produto } from 'app/interfaces/enum/produto.enum';
import { AuthService } from 'app/main/pages/authentication/auth.service';

@Component({
  selector: 'app-produto-select',
  templateUrl: './produto-select.component.html',
  styleUrls: ['./produto-select.component.scss']
})
export class ProdutoSelectComponent implements OnInit {

  // Opção principal
  @Input() filtrarProdutoPorUsuarioLogado: boolean = true;

  // NG-MODEL
  @Input() produto;
  @Output() produtoChange = new EventEmitter<any>();

  // FORM CONTROL
  @Input() fControlName: string = "produto";
  form: FormGroup;

  // INPUT OPTIONS OPTIONS
  @Input() clearable: boolean = false;
  @Input() closeOnSelect: boolean = true;
  @Input() placeholder: string = "Produto";
  @Input() searchable: boolean = true;
  @Input() typeToSearchText: string = "Digite para Procurar"
  @Input() notFoundText: string = "Nenhum item encontrado"
  @Input() readonly: boolean = false;
  @Input() multiple: boolean = false;
  @Input() maxSelectedItems: number;
  @Input() maxItemsToShow: number = 999;
  

  produtos = Produto;
  filteredProducts = [] as EnumGenerico[];

  constructor(
    private _authService: AuthService,
    private rootFormGroup: FormGroupDirective
  ) {
  }

  ngOnInit(): void {
    this.filtrarProdutos();
    this.form = this.rootFormGroup.form;
  }

  onChangeProduto() {
    this.produtoChange.emit(this.produto);
  }


  /**
 * Cria uma lista de produtos;
 **/

  filtrarProdutos() {
    // Limpando a lista;
    this.filteredProducts = [];
    
    if (this.filtrarProdutoPorUsuarioLogado) {
      const produtosUsuario = this._authService.getTokenProdutos();
      // Transformando enum em uma lista
      for (const valor of produtosUsuario) {
        this.filteredProducts.push({
          key: valor,
          value: Produto[valor]
        });
      }
    } else {
      // Transformando enum em uma lista
      for (const valor in Produto) {
        this.filteredProducts.push({
          key: valor,
          value: Produto[valor]
        });
      }
    }

  }
}
