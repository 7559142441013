import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule } from "@core/components";
import { CoreModule } from "@core/core.module";
import { OwlDateTimeIntl } from "@danielmoncada/angular-datetime-picker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { coreConfig } from "app/app-config";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import "hammerjs";
import { KeyboardShortcutsModule } from "ng-keyboard-shortcuts";
import { CookieService } from "ngx-cookie-service";
import { NgxMaskModule } from "ngx-mask";
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { NgxPermissionsModule } from "ngx-permissions";
import { NgProgressModule } from "ngx-progressbar";
import { ToastrModule } from "ngx-toastr"; // For auth after login toast
import { HomeModule } from "./main/home/home.module";
import { TokenInterceptor } from "./main/pages/authentication/TokenInterceptor";
import { AuthGuard } from "./main/pages/authentication/guards/auth.guards";
import { DefaultDateTimeIntl } from "./utils/default-datetime.service";
import { DirectivesModule } from "./utils/directives/directives.module";
import { PermissaoRecursoGuard } from "./utils/guards/permissao-recurso.guards";
import { IconsService } from "./utils/icons.service";
import { PermissaoRecursoService } from "./utils/permissao-recurso.service";
import { AtividadesAtrasadasService } from "./utils/atividades-atrasadas.service";
import { NgxDocViewerModule } from "ngx-doc-viewer";

const appRoutes: Routes = [

  { path: "origemdestino", loadChildren: () => import("./main/origemdestino/origemdestino.module").then(m => m.OrigemDestinoModule) },
  { path: "tipocontainer", loadChildren: () => import("./main/tipocontainer/tipocontainer.module").then(m => m.TipocontainerModule) },
  { path: "auth", loadChildren: () => import("./main/pages/pages.module").then(m => m.PagesModule) },
  { path: "usuario", loadChildren: () => import("./main/usuario/usuario.module").then(m => m.UsuarioModule) },
  { path: "empresa", loadChildren: () => import("./main/empresa/empresa.module").then(m => m.EmpresaModule) },
  { path: "role", loadChildren: () => import("./main/role/role.module").then(m => m.RoleModule) },
  { path: "recurso", loadChildren: () => import("./main/recurso/recurso.module").then(m => m.RecursoModule) },
  { path: "permissao", loadChildren: () => import("./main/permissao/permissao.module").then(m => m.PermissaoModule) },
  { path: "grupo", loadChildren: () => import("./main/grupo/grupo.module").then(m => m.GrupoModule) },
  { path: "moeda", loadChildren: () => import("./main/moeda/moeda.module").then(m => m.MoedaModule) },
  { path: "pais", loadChildren: () => import("./main/pais/pais.module").then(m => m.PaisModule) },
  { path: "funcaoParceiro", loadChildren: () => import("./main/funcaoparceiro/funcaoparceiro.module").then(m => m.FuncaoParceiroModule) },
  { path: "parceiro", loadChildren: () => import("./main/parceiro/parceiro.module").then(m => m.ParceiroModule) },
  { path: "estado", loadChildren: () => import("./main/estado/estado.module").then(m => m.EstadoModule) },
  { path: "cidade", loadChildren: () => import("./main/cidade/cidade.module").then(m => m.CidadeModule) },
  {
    path: "custoFinanceiroCategoria",
    loadChildren: () => import("./main/custofinanceirocategoria/custofinanceirocategoria.module").then(m => m.CustoFinanceiroCategoriaModule),
  },
  {
    path: "custoFinanceiro",
    loadChildren: () => import("./main/custofinanceiro/custofinanceiro.module").then(m => m.CustoFinanceiroModule),
  },
  { path: "familia", loadChildren: () => import("./main/familia/familia.module").then(m => m.FamiliaModule) },
  { path: "incoterm", loadChildren: () => import("./main/incoterm/incoterm.module").then(m => m.IncotermModule) },
  { path: "regraCalculo", loadChildren: () => import("./main/regracalculo/regracalculo.module").then(m => m.RegraCalculoModule) },
  { path: "embalagem", loadChildren: () => import("./main/embalagem/embalagem.module").then(m => m.EmbalagemModule) },
  { path: "mercadoria", loadChildren: () => import("./main/mercadoria/mercadoria.module").then(m => m.MercadoriaModule) },
  { path: "navio", loadChildren: () => import("./main/navio/navio.module").then(m => m.NavioModule) },
  { path: "tarifario", loadChildren: () => import("./main/tarifario/tarifario.module").then(m => m.TarifarioModule) },
  { path: "tarifariolocal", loadChildren: () => import("./main/tarifariolocal/tarifariolocal.module").then(m => m.TarifarioLocalModule) },
  { path: "tarifarioTaxa", loadChildren: () => import("./main/tarifariotaxa/tarifariotaxa.module").then(m => m.TarifarioTaxaModule) },
  {
    path: "tarifario-demurrage",
    loadChildren: () => import("./main/tarifario-demurrage/tarifario-demurrage.module").then(m => m.TarifarioDemurrageModule),
  },
  { path: "rota", loadChildren: () => import("./main/rota/rota.module").then(m => m.RotaModule) },
  { path: "escala", loadChildren: () => import("./main/escala/escala.module").then(m => m.EscalaModule) },
  { path: "inventario", loadChildren: () => import("./main/inventario/inventario.module").then(m => m.InventarioModule) },
  { path: "agenda", loadChildren: () => import("./main/agenda/agenda.module").then(m => m.AgendaModule) },
  { path: "oportunidade", loadChildren: () => import("./main/oportunidade/oportunidade.module").then(m => m.OportunidadeModule) },
  {
    path: "oportunidadeOferta",
    loadChildren: () => import("./main/oportunidadeoferta/oportunidadeoferta.module").then(m => m.OportunidadeOfertaModule),
  },
  { path: "todo", loadChildren: () => import("./main/agenda-todo/agenda-todo.module").then(m => m.AgendaTodoModule) },
  { path: "meta", loadChildren: () => import("./main/meta/meta.module").then(m => m.MetaModule) },
  { path: "email", loadChildren: () => import("./main/usuario-email/usuario-email.module").then(m => m.UsuarioEmailModule) },
  { path: "banco", loadChildren: () => import("./main/banco/banco.module").then(m => m.BancoModule) },
  { path: "fatura", loadChildren: () => import("./main/fatura/fatura.module").then(m => m.FaturaModule) },
  {
    path: "categoria-financeira",
    loadChildren: () => import("./main/categoria-financeira/categoria-financeira.module").then(m => m.CategoriaFinanceiraModule),
  },
  { path: "conta-bancaria", loadChildren: () => import("./main/conta-bancaria/conta-bancaria.module").then(m => m.ContaBancariaModule) },
  {
    path: "extrato-bancario",
    loadChildren: () => import("./main/extrato-bancario/extrato-bancario.module").then(m => m.ExtratoBancarioModule),
  },
  { path: "conta-contabil", loadChildren: () => import("./main/conta-contabil/conta-contabil.module").then(m => m.ContaContabilModule) },
  { path: "despesa", loadChildren: () => import("./main/despesa/despesa.module").then(m => m.DespesaModule) },
  { path: "centro-custo", loadChildren: () => import("./main/centro-custo/centro-custo.module").then(m => m.CentroCustoModule) },
  { path: "budget", loadChildren: () => import("./main/budget/budget.module").then(m => m.BudgetModule) },
  { path: "processo", loadChildren: () => import("./main/processo/processo.module").then(m => m.ProcessoModule) },
  {
    path: "agenda-automatica",
    loadChildren: () => import("./main/config-atividades/config-atividades.module").then(m => m.ConfigAtividadesModule),
  },
  { path: "cargo", loadChildren: () => import("./main/cargo/cargo.module").then(m => m.CargoModule) },
  { path: "departamento", loadChildren: () => import("./main/departamento/departamento.module").then(m => m.DepartamentoModule) },
  { path: "filial", loadChildren: () => import("./main/filial/filial.module").then(m => m.FilialModule) },
  { path: "relatorios", loadChildren: () => import("./main/relatorio/relatorio.module").then(m => m.RelatorioModule) },
  { path: "farolMetas", loadChildren: () => import("./main/farolmetas/farol-metas.module").then(m => m.FarolMetasModule) },
  { path: "frase-padrao", loadChildren: () => import("./main/frase-padrao/frase-padrao.module").then(m => m.FrasePadraoModule) },
  {
    path: "devolucao-container",
    loadChildren: () => import("./main/devolucao-container/devolucao-container.module").then(m => m.DevolucaoContainerModule),
  },
  {
    path: "controle-booking",
    loadChildren: () => import("./main/controle-booking/controle-booking.module").then(m => m.ControleBookingModule),
  },
  { path: "modelo-email", loadChildren: () => import("./main/modelo-email/modelo-email.module").then(m => m.ModeloEmailModule) },
  { path: "query-dinamica", loadChildren: () => import("./main/query-dinamica/query-dinamica.module").then(m => m.QueryDinamicaModule) },
  { path: "parametro", loadChildren: () => import("./main/parametro/parametro.module").then(m => m.ParametroModule) },
  { path: "recinto-alfandegado", loadChildren: () => import("./main/recinto-alfandegado/recinto-alfandegado.module").then(m => m.RecintoAlfandegadoModule) },
  { path: "unidade-receita-federal", loadChildren: () => import("./main/unidade-receita-federal/unidade-receita-federal.module").then(m => m.UnidadeReceitaFederalModule) },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/auth/error", //Error 404 - Page not found
  },
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

registerLocaleData(localePt);

@NgModule({
  providers: [
    PermissaoRecursoGuard,
    PermissaoRecursoService,
    AtividadesAtrasadasService,
    IconsService,
    CookieService,
    TokenInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthGuard,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: OwlDateTimeIntl,
      useClass: DefaultDateTimeIntl,
    },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    TranslateModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgProgressModule.withConfig({
      color: "#28c76f",
      spinner: false,

    }),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    NgxPermissionsModule.forRoot(),
    CoreCommonModule,
    CoreSidebarModule,

    // App modules
    LayoutModule,
    DirectivesModule,
    KeyboardShortcutsModule.forRoot(),
    HomeModule,
    NgxDocViewerModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
