import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { AuthGuard } from '../pages/authentication/guards/auth.guards';
import { HomeComponent } from './home.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeIframeComponent } from './home-iframe/home-iframe.component';


const routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    component: HomeComponent,
    data: { animation: 'home' }
  }
];


@NgModule({
  declarations: [HomeComponent, HomeIframeComponent],
  imports: [
    RouterModule.forChild(routes), 
    ContentHeaderModule, 
    TranslateModule, 
    NgbNavModule,
    CoreCommonModule],
  exports: [HomeComponent]
})
export class HomeModule { }
