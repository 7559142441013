import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'app/app.service';
import { Pageable } from 'app/interfaces/Pageable/pageable';
import handleErrorMessage from 'app/utils/error-message-handler';
import { MessageService } from 'app/utils/toastr-custom/message.service';
import { UsuarioHttpService } from '../usuario-http.service';
import { UsuarioService } from '../usuario.service';
import { Usuario } from 'app/interfaces/usuario';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Delta, DiffPatcher } from 'jsondiffpatch';
import { auditObjetoAlterado } from 'app/interfaces/audit-objeto-alterado';
import { ComparaJsonAuditoriaService } from 'app/utils/compara-json-auditoria.service';
import { TipoAuditoria } from 'app/interfaces/enum/tipo-auditoria';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-usuario-audit',
  templateUrl: './usuario-audit.component.html',
  styleUrls: ['./usuario-audit.component.scss'],
})
export class UsuarioAuditComponent implements OnInit {
  form: FormGroup;

  anonymizationDiffDbDictionary: { [id: string]: Delta } = {};
  jsondiffpatch = new DiffPatcher();
  anonymizationGapDiff: Delta;
  partnerAnonymized2: any;
  partnerAnoPatched: any;

  auditObjetosAlterados = [] as auditObjetoAlterado[];
  dataModificacao: string;
  auditSelecionada: any;

  @Input() usuarios = {} as Usuario;

  constructor(
    public appService: AppService,
    private _fb: FormBuilder,
    private _activeModal: NgbActiveModal,
    private _usuarioService: UsuarioService,
    private _appService: AppService,
    private _messageService: MessageService,
    private _usuarioHttpService: UsuarioHttpService,
    private _comparaJsonAuditoria: ComparaJsonAuditoriaService
  ) {}

  audits: Pageable;
  pageIndex = 1;
  tipoAuditoria = TipoAuditoria;

  flatpickrOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'single',
    altInputClass: 'form-control',
    enableTime: true,
    time_24hr: true,
    altFormat: 'd/m/Y - H:i',
    allowInput: true,
  };

  ngOnInit(): void {
    this.initForm();
    this.getAuditorias();
  }

  /**
   * Função para filtrar a table;
   */
  filtrar(page = 1) {
    this.appService.progressSpinnerDlg = true;

    this._usuarioHttpService
      .getAllAudit(
        this.usuarios.usuId,
        page - 1,
        this.f.email?.value,
        this.f.dataInicial?.value,
        this.f.dataFinal?.value,
        this.f.tipoAuditoria?.value
      )
      .subscribe({
        next: result => {
          this.audits = result;
        },
        error: error => {
          handleErrorMessage(this._messageService, error);
        },
      })
      .add(() => {
        this.appService.progressSpinnerDlg = false;
      });
  }

  getAuditorias(page = 1) {
    this._appService.progressSpinnerDlg = true;
    this._usuarioHttpService
      .getAllAudit(
        this.usuarios.usuId,
        page - 1,
        this.f.email?.value,
        this.f.dataInicial?.value,
        this.f.dataFinal?.value,
        this.f.tipoAuditoria?.value
      )
      .subscribe({
        next: result => {
          this.audits = result;
        },
        error: err => {
          console.log(err);
          handleErrorMessage(this._messageService, err);
        },
      })
      .add(() => {
        this._appService.progressSpinnerDlg = false;
      });
  }

  selecionaAudit(audit: any) {
    this.auditSelecionada = audit;
    this.auditObjetosAlterados = [];

    this.appService.progressSpinnerDlg = true;
    this._usuarioHttpService
      .getAuditComparacao(audit.usuId, audit.rev)
      .subscribe({
        next: result => {
          const objetoNovo = result[0];
          this.auditObjetosAlterados = this._comparaJsonAuditoria.comparaObjetos(objetoNovo.revType === 0 ? null : result[1], result[0], audit.usuId);
        },
        error: error => {
          handleErrorMessage(this._messageService, error);
        },
      })
      .add(() => {
        this.appService.progressSpinnerDlg = false;
      });
  }

  close(alterado = false) {
    this._activeModal.close(alterado);
  }

  initForm() {
    this.form = this._fb.group({
      email: '',
      dataInicial: '',
      dataFinal: '',
      tipoAuditoria: '',
    });
  }

  get f() {
    return this.form.controls;
  }

  limparFiltro(page = 1) {
    this.appService.progressSpinnerDlg = true;

    this._usuarioHttpService
      .getAllAudit(
        this.usuarios.usuId,
        page - 1,
        this.f.email?.value,
        this.f.dataInicial?.value,
        this.f.dataFinal?.value,
        this.f.tipoAuditoria?.value
      )
      .subscribe({
        next: result => {
          this.audits = result;
        },
        error: error => {
          handleErrorMessage(this._messageService, error);
        },
      })
      .add(() => {
        this.appService.progressSpinnerDlg = false;
      });
  }
}
