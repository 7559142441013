import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  private _userTimeZone: string;
  private _format: string;
  private _emailFormat: string;

  private _weekdays = [
    "domingo",
    "segunda-feira",
    "terça-feira",
    "quarta-feira",
    "quinta-feira",
    "sexta-feira",
    "sábado"
  ]


  constructor() {
    this._userTimeZone = 'GMT';
    this._format = 'YYYY-MM-DDTHH:mm:ss';
    this._emailFormat = 'DD/MM/YYYY - HH:mm';
  }

  public utcToUser(utcDateTime: Moment): Moment {
    return moment.tz(utcDateTime + 'Z', this._userTimeZone);
  }

  public utcToUserString(utcDateTime: any): string {
    return moment(utcDateTime + 'Z').tz(this._userTimeZone).format(this._format);
  }

  public userToUtc(userDateTime: Moment): Moment {
    return moment(userDateTime + 'Z').utc();
  }

  public userToUtcString(utcDateTime: Moment): string {
    return moment(utcDateTime).utc().format(this._format);
  }

  public dateToPtBrBeautyString(utcDateTime: Date): string {
    return moment(utcDateTime).locale('pt-br').format("LLLL");
  }

  get todayUserToUtcString() {
    return this.utcToUserString(new Date()).split('T')[0];
  }


  get userTimeZone() {
    return this._userTimeZone
  }

  get format() {
    return this._format;
  }


  public setUserTimeZone(userTz: string) {
    this._userTimeZone = userTz;
  }

  public setFormat(format: string) {
    this._format = format;
  }

}